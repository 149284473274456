import { createApp } from 'vue';
import store from 'shared/store';
import CtaBannerBlock from './CtaBannerBlock.vue';
import '@coloplast/design-system/dist/components/banner/ds-banner-xl.css';

const ctaBannerBlocks = document.querySelectorAll(
	'[data-block-name="cta-banner-block"]'
);

if (ctaBannerBlocks) {
	ctaBannerBlocks.forEach((ctaBannerBlockElem) => {
		const app = createApp({
			name: 'CtaBannerBlockApp',
		});
		app.use(store);
		app.component('cta-banner-block', CtaBannerBlock);
		app.mount(ctaBannerBlockElem);
	});
}
