<template>
	<slot v-if="!loaded"></slot>
	<DsBlock v-if="vm && loaded">
		<DsBannerXl
			:background="vm.CurrentBlock.BackgroundColor"
			:button-click-handler="buttonClickHandler"
			:button-href="vm.CurrentBlock.ButtonLink"
			:button-text="buttonText"
			:description="vm.CurrentBlock.DescriptionText"
			:media-alt-text="vm.CurrentBlock.ImageAltTex"
			:media-focal-point="vm.CurrentBlock.MediaFocalPoint"
			:media-focal-point-mobile="vm.CurrentBlock.MediaFocalPointMobile"
			:media-placement="vm.CurrentBlock.MediaPlacement"
			:media-placement-mobile="vm.CurrentBlock.MediaPlacementMobile"
			:media-render-mode="vm.CurrentBlock.MediaRenderMode"
			:media-render-mode-mobile="vm.CurrentBlock.MediaRenderModeMobile"
			:media-src="vm.BaseViewModel.ImageUrl"
			:text-wrap-mode="vm.CurrentBlock.TextWrapMode"
			:title-large="vm.CurrentBlock.TitleLarge"
			:title-small="vm.CurrentBlock.TitleSmall"
			:title-small-html-element="vm.CurrentBlock.TitleLargeHtmlElement"
			:title-large-html-element="vm.CurrentBlock.TitleSmallHtmlElement"
		></DsBannerXl>
		<AuthenticateModal
			v-if="vm.BaseViewModel.IsAuthenticationRequired"
			:vm="vm"
			:disabledRedirectAfterClosing="true"
		>
		</AuthenticateModal>
	</DsBlock>
</template>
<script lang="ts" setup>
import { computed, defineAsyncComponent, onMounted, ref } from 'vue';
import { DsBannerXl, DsBlock } from '@coloplast/design-system';
import { useStore } from 'vuex';

import type {
	BannerBackgroundOption,
	BannerMediaFocalPointMobileOption,
	BannerMediaPlacementMobileOption,
	BannerMediaPlacementOption,
	BannerMediaRenderModeMobileOption,
	BannerMediaRenderModeOption,
} from '@coloplast/design-system/types/components/banner/interface.d.ts';
import { type ObjectPositionOption } from '@coloplast/design-system/dist/types/types/object-position.d.ts';

export type AuthenticateModalViewModel = {
	AuthenticateModalHeading: string;
	CanProceedWithoutRegistering: boolean;
	CurrentBlockId: number;
	IsAuthenticated: boolean;
	IsHcpModal: boolean;
	PrimaryButtonViewModel: {
		ToShow: boolean;
		ButtonAction: number;
	};
	ProceedWithoutRegisteringDescriptionText: string;
	ProceedWithoutRegisteringUrlText: string;
	SecondaryButtonViewModel: {
		ToShow: boolean;
		ButtonAction: number;
	};
	ShowModal: boolean;
	SignInButtonText: string;
	SignInDescriptionText: string;
	SignInHeading: string;
	SignInUrl: string;
	SignUpButtonText: string;
	SignUpDescriptionText: string;
	SignUpHeading: string;
	SignUpReturnUrl: string;
	SupportingTextAndLinkViewModel: {
		ToShow: boolean;
		ButtonAction: number;
	};
	UspList: string[];
};

export type BaseViewModel = {
	AuthenticateModalViewModel: AuthenticateModalViewModel;
	IsAuthenticated: boolean;
	IsAuthenticationRequired: boolean;
	CurrentBlockId: number;
	ImageUrl: string;
};

export type CurrentBlock = {
	TitleLarge: string;
	TitleLargeHtmlElement?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
	TitleSmall: string;
	TitleSmallHtmlElement?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
	DescriptionText: string;
	Image: string;
	ImageAltTex: string;
	LoggedInUserButtonText: string;
	LoggedOutUserButtonText: string;
	ButtonLink: string;
	BackgroundColor?: BannerBackgroundOption;
	TextWrapMode: "wrap" | "balance";
	MediaSizes: string;
	MediaSrcSet: string;
	MediaPlacement?: BannerMediaPlacementOption;
	MediaPlacementMobile?: BannerMediaPlacementMobileOption;
	MediaFocalPoint: ObjectPositionOption;
	MediaFocalPointMobile: BannerMediaFocalPointMobileOption;
	MediaRenderMode?: BannerMediaRenderModeOption;
	MediaRenderModeMobile: BannerMediaRenderModeMobileOption;
	IsAuthenticationRequired: boolean;
	AuthenticateModel: {
		AuthenticateModalHeading: string;
		IsHcpModal: string;
		HcpLoginAzureFlow: string;
		ActionOrder: string;
	};
};

type CtaBannerViewModel = {
	BaseViewModel: BaseViewModel;
	CurrentBlock: CurrentBlock;
};

export type CtaBannerBlockProps = {
	vm: CtaBannerViewModel;
};

export type CtaBannerModalProps = {
	vm: CtaBannerViewModel;
}

// Only fetch the modal code if needed
const AuthenticateModal = defineAsyncComponent(() =>
	import('multisite/components/checkout/checkoutModalLogin.vue')
);

const loaded = ref(false);

onMounted(() => {
	loaded.value = true;
});

const store = useStore();

const props = defineProps<CtaBannerBlockProps>();

// Button text is conditional based on whether the user is logged in or not
const buttonText = computed(() => {
	if (props.vm.BaseViewModel.IsAuthenticated) {
		return props.vm.CurrentBlock.LoggedInUserButtonText;
	} else {
		return props.vm.CurrentBlock.LoggedOutUserButtonText;
	}
});

const shouldTriggerModal = computed(() => {
	return props.vm.BaseViewModel.IsAuthenticationRequired && !props.vm.BaseViewModel.IsAuthenticated;
});

// Trigger the login flow
const buttonClickHandler = (e: MouseEvent) => {
	if (shouldTriggerModal.value) {
		e.preventDefault();
		store.commit("globalCheckout/setLoginModalShow", true);
	}
};
</script>
